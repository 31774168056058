import { useState, useEffect, useMemo } from 'react';
import { BehaviorSubject } from 'rxjs';
import u, { ELocalKey } from '@/util';

export const config$ = new BehaviorSubject({});

const useConfig = () => {
    const [state, setState] = useState<any>();

    const localData = useMemo(() => {
        try {
            const __data = localStorage.getItem("_CONF");
            let val = JSON.parse(u.decrypt(__data))
            return val
        } catch (error) {
            return {}
        }
    }, [])

    useEffect(() => {
        const subscription = config$.subscribe(setState);
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (state) {
            localStorage.setItem(ELocalKey._CONF, u.encrypt(JSON.stringify(state)))
        }
    }, [state])

    const updateState = (val: any) => {
        localStorage.setItem(ELocalKey._CONF, u.encrypt(JSON.stringify(val.data)))
        config$.next(val.data)
    };

    return [localData, updateState] as [any, (val: any) => void];
}
export default useConfig;