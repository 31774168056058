import React, { FC } from "react"

interface IText {
    type?: "red" | "bold" | "small_b" | "small_g",
    children?: React.ReactNode
}

const Text: FC<IText> = ({ type, children }) => {
    return (
        <div className={type}>
            {children}
        </div>
    )
}
export default Text