import ImageList from "@/components/list";
import Footer from "@/components/footer";
import Pagination from "@/components/pagination";
import useAxios from "@/hooks/useAxios";
import useCategory from "@/hooks/useCategory";
import { useParams } from "react-router-dom";
import { FC, useCallback, useEffect, useState } from "react"
import Back from "@/components/back";
import { cancelAllPreviousFetchRequests } from "@/util";

interface CategoryVideo {
}

const CategoryNyou: FC<CategoryVideo> = () => {
    const { channel, id } = useParams();
    const category = useCategory()
    const { req: req2 } = useAxios("shipin/nvyou", "post");
    const [dta, setDta] = useState<any>({})
    const [list, setList] = useState<any>(null)
    const [reset, setReset] = useState<boolean>(false)

    const fetchDataList = useCallback(async (page?: number) => {
        if (!id) return;

        const res = await req2({
            id: id,
            page: page || 1
        })
        if (res?.data) {
            setDta(res?.data)
            setList(res?.data?.list?.data);
        }
        setReset(false)
    }, [channel, id, category])

    useEffect(() => {
        fetchDataList()
    }, [fetchDataList])

    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <Back>{dta?.nvyou}</Back>
                <ImageList reset={reset} channel={channel} list={list} idkey="title" idValue="id" imgUrlkey="thumb" />
            </div>

            <Pagination cancelFn={cancelAllPreviousFetchRequests} lastPg={dta?.list?.last_page} total={dta?.list?.total} changeHandler={ async (e) => {
                await fetchDataList(e.current)
                setReset(true)
            }} />
            <Footer />
        </div>
    )
}
export default CategoryNyou