import { FC, useCallback, useEffect, useState } from "react";
import Header from "@/components/header";
import Slider from "@/components/slide";
import Category from "@/components/category";
import VipCard from "./vipCard";
import BannerList from "./bannerList";
import u from "@/util";
import RelateList from "./relateList";
import FastSection from "./fastSection";
import MsgModal from "@/components/modal/msgModa";
import Download from "@/components/download";
import layoutStyles from "@/components/styles/layout.module.css"
import styles from "@/components/styles/categoryHead.module.css"
import useConfBase from "@/hooks/useConfBase";
import useAxios from "@/hooks/useAxios";
// import FloatLR from "./floatLR";

interface ICategoryHead {
    showCategory?: boolean;
    children?: React.ReactNode;
    hideHead?: boolean;
    flag?: 0 | 1,
    hideAds?: boolean
}

const CategoryHead: FC<ICategoryHead> = ({ showCategory, children, hideHead, flag, hideAds }) => {
    const [isScroll, setIsScroll] = useState<number>(0)
    const [config, updateConfBase, getData] = useConfBase();
    const onScroll = () => setIsScroll(window.pageYOffset);
    const [siteCili, setSiteCili] = useState<"1" | "0">("0")
    const [siteTopic, setSiteTopic] = useState<"1" | "0">("0")
    const { req } = useAxios("index/statistics", 'post', true);

    useEffect(() => {
        setSiteCili(config?.site_cili || "0")
        setSiteTopic(config?.site_topic ?? "1")
    }, [config])

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    }, []);

    const getAddiotionalHideCondition = useCallback(() => {
        if (u.isPermenantVip()) {
            return false;
        }
        if (u.hideAdsOnCertainPages()) {
            return false;
        }
        if (!hideAds === false) {
            return false
        }
        return true
    }, [hideAds])

    const cilihandler = useCallback(async (type: any) => {
        const __val = u.getStatsParams(false, "", "", window.location.pathname);
        // req({ ...__val, category: "site", name: type ? "pic" : "cili" });
        if (process.env.NODE_ENV === "production") {
            const val = type ? "pic." : "download."
            let urlary = window.location.origin.split("https://") as any;
            if (urlary.length === 1) {
                urlary = window.location.origin.split("http://") as any;
            }
            if (urlary.length === 2) {
                urlary[0] = val;
            }
            urlary = urlary.join("").replace("www.", "");
            // const slash = urlary.lastIndexOf("/");
            // urlary = urlary.substring(0, slash);
            return u.open(`https://${urlary}`)
        }
        return u.open(type ? config?.pic_url : config?.cili_url)
    }, [])

    const routeToTopic = useCallback(async () => {
        return u.open("/topic")
    }, [])
    
    return (
        <>
            <div onScroll={onScroll} className={`grid ${layoutStyles.layout}`}>
                <Header hideHead={hideHead} isScroll={!!isScroll} />
                {showCategory && <div className={`m0auto ${styles.ch}`}>
                    <div>
                        <div className={`${styles.banner_def} ${u.isPermenantVip() ? styles.banner_vip_def : ''}`}>
                            {!u.isPermenantVip() && <Slider />}
                        </div>
                        <div className={`grid ${layoutStyles.category}`}>
                            <Category showSiteCili={false} />
                            <div>
                                {<VipCard />}
                            </div>
                        </div>

                        {/* {siteTopic === '1' && 
                         <div className={`${styles.topic_banner}`} onClick={() => {
                                    routeToTopic()
                                }}>
                        <img className={`grid ${styles.banner}`} src={appendHost("/images/topic_banner.gif")} alt="" />
                        </div>
                        } */}
                       

                        {window.location.href.indexOf("topic") === -1 && <>
                            {getAddiotionalHideCondition() && <BannerList flag={flag} />}
                            {getAddiotionalHideCondition() && u.isMobile() && <RelateList />}
                        </>}
                     
                    </div>
                </div>}
                <div>
                    {children && children}
                </div>
            </div>
            <FastSection />
            <MsgModal />
            <Download />
        </>
    )
}
export default CategoryHead;