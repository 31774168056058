import { validFn, validMsg, vID } from "@/const/validate.const";
import { useEffect, useMemo, useState } from "react";

const useValidation = (formData: any, rules: { [key: string]: any[] }, addOnKey?: string) => {
    const [msgStatus, setMsgStatus] = useState<any[]>([]);
    const [init, setInit] = useState<boolean>(true)
    const isValidSubmit = useMemo(() => msgStatus.every((t) => t === true), [msgStatus])
    
    useEffect(() => {
        const rulesKeys = Object.keys(rules);
        const rulesValues = Object.values(rules);
        if (init) {
            const isChanged = Object.values(formData).some((x) => x !== "");
            if (isChanged) {
                setInit(false)
            }
        }

        const result = rulesValues.map((x, i) => {
            let rulesId = "1"
            let isMatchTrue = true;
            const isTrue = x.every(t => {
                if (t === vID.PWD_PWD2_NOT_MATCH && addOnKey) {
                    isMatchTrue = validFn[t](formData[rulesKeys[i]], formData[addOnKey])
                } else {
                    isMatchTrue = validFn[t](formData[rulesKeys[i]])
                }
                if (!isMatchTrue) {
                    rulesId = t;
                }
                return isMatchTrue
            });
            return init ? [] :  isTrue || validMsg(rulesId)
        });
        setMsgStatus(result);
    }, [formData])

    return [isValidSubmit, msgStatus] as [boolean, any]
}
export default useValidation;