
import { FC, useEffect, useState } from "react";
import Img from "@/components/img";
import { appendHost } from "@/const/append";

import styles from "@/components/styles/buyCard.module.css"
import u from "@/util";
import useConfBase from "@/hooks/useConfBase";
import { Link } from "react-router-dom";
import useAxios from "@/hooks/useAxios"
import useUser from "@/hooks/useUser"
interface IBuycard {
    smallView?: boolean
}

const BuyCard: FC<IBuycard> = ({ smallView }) => {
    const [config, updateConfBase, getData] = useConfBase()
    // const { req } = useAxios("mall/goodList", "post");
    const [time, setTime] = useState<string>("24:00:00")
    const [data, setData] = useState<any[]>([])
    const { req } = useAxios("mall/goodList", "post");
    const [id, setId] = useState()
    const [userInfo] = useUser();
    // const fetchProductDetails = useCallback( async () => {
    //     const res = await req({});
    //     if (res) {
    //         console.log(res?.data)
    //         if (Array.isArray(res?.data)) {
    //             console.log(res)
    //             setData(res?.data)
    //         } 
    //     }
    // }, [])

    // useEffect(() => {
    //     fetchProductDetails()
    // }, [fetchProductDetails])

    const routeToTopic = () => {
        return u.open("/topic")
    }

      useEffect(() => {
        u.countdown(setTime)
      }, [])

      useEffect(() => {
        getData(2).then( async (res: any) => {
            if (smallView) {
                delete res[0]
            }

            if(res.length){
                console.log('goods storage' , res)
                localStorage.setItem("__GD", JSON.stringify(res));
                // res[0].description = "活动促销";
                setData(res)
                res.map((item: any) => {
                    if(item.title === '终身VIP'){
                        setId(item.id)
                    }
                })
            }else{
                const res = await req()

                if (res) {
                    console.log('goods' , res)
                    localStorage.setItem("__GD", JSON.stringify(res));
                    setData(res.data)
                    res.map((item: any) => {
                        if(item.title === '终身VIP'){
                            setId(item.id)
                        }
                    })
                }
            }
        });
      }, [req])

    return (
        <div className={`mw1100 ${smallView && styles.smallView}`}>
            <div className={smallView ? styles.mobilepostwrapper:styles.postwrapper} >
                <div onClick={() => window.open(`/user/charge?id=${id}`)}>
                <Img cls={``} style={{ width: "100%", height: smallView ? "auto" :"180px", objectFit:'contain' }} src={appendHost(`/images/post_vip.jpg`)} width={630} />
                </div>
                <div onClick={() => { routeToTopic()}}>
                <Img cls={``} style={{ width: "100%", height: smallView ? "auto" :"180px", objectFit:'contain' }} src={appendHost(`/images/post_zhuti.jpg`)} width={630} />
                </div>
            </div>
            {/* <div className="relative">
                <Img cls={`of_cover ${styles.top}`} height={180} src={appendHost("/images/user-center/buy/buy_bg.png")} />
                <div className={`abs ${styles.buy_main}`}>
                    <div className={styles.n_1}>终身VIP限时优惠</div>
                    <div className={styles.n_1}>{time}</div>
                    <div>
                        <div className={styles.n_2}>
                            限时优惠<span>200.00</span>元 <span className="text_line">原价5000.00</span>
                        </div>
                        <div className={styles.n_3}  onClick={() => {
                                    routeToTopic()
                                }}>
                            跳转主题列表
                        </div>
                    </div>
                </div>
            </div> */}
            <div>
                {/* <div className={smallView ? styles.mobileimportanttext:styles.importanttext}>*主题包的兑换码，将发送至消息列表中，用户需自行到兑换页面兑换心仪主题包</div> */}
                {data.map((x, i) => {
                    return <Link key={i} className="d_none point" to={`/user/charge?id=${x.id}`}>
                        {!!x.description && (
                        <div className={`${styles.activitymark}`} style={{color:'white', background:'linear-gradient(90deg, rgba(235,130,130,1) 0%, rgba(250,5,5,1) 20%, rgba(235,130,130,1) 100%)', padding:'6px 15px', marginTop:'0', borderRadius:'6px 6px 0px 0px'}}>
                            {x.description}
                        </div>
                        )}
                        {/* {x.id === 5 && (
                        <div className={`${styles.activitymark}`} style={{color:'white', background:'linear-gradient(90deg, rgba(235,130,130,1) 0%, rgba(250,5,5,1) 20%, rgba(235,130,130,1) 100%)', padding:'6px 15px', marginTop:'0', borderRadius:'6px 6px 0px 0px'}}>
                            端午大促加赠3天vip（共10天）
                        </div>
                        )}
                          {x.id === 1 && (
                        <div className={`${styles.activitymark}`} style={{color:'white', background:'linear-gradient(90deg, rgba(235,130,130,1) 0%, rgba(250,5,5,1) 20%, rgba(235,130,130,1) 100%)', padding:'6px 15px',  marginTop:'13px', borderRadius:'6px 6px 0px 0px'}}>
                            端午大促加赠1个月vip（共2个月）
                        </div>
                        )}
                          {x.id === 2 && (
                        <div className={`${styles.activitymark}`} style={{color:'white', background:'linear-gradient(90deg, rgba(235,130,130,1) 0%, rgba(250,5,5,1) 20%, rgba(235,130,130,1) 100%)', padding:'6px 15px',  marginTop:'13px', borderRadius:'6px 6px 0px 0px'}}>
                            端午大促加赠3个月vip（共6个月）
                        </div>
                        )}
                        {x.id === 4 && (
                        <div className={`${styles.activitymark}`} style={{color:'white', background:'linear-gradient(90deg, rgba(235,130,130,1) 0%, rgba(250,5,5,1) 20%, rgba(235,130,130,1) 100%)', padding:'6px 15px',  marginTop:'13px', borderRadius:'6px 6px 0px 0px'}}>
                            端午特惠加赠热门限时专题包（P站俄妹+玩偶姐姐）
                        </div>
                        )} */}
                        <div className={`${(x.description) ? styles.vip_item_event : styles.vip_item} grid align_center`}>
                       {/* <div className={`${styles.vip_item} grid align_center`}> */}
                            <div className="grid align_center h100 gap5">
                                <div className="fl align_center gap10">
                                    <div className={`${styles.vip_item_title}`}>{x.title}</div>
                                    {x.activity === 1 && (
                                        <span className={`${styles.activitymark}`}>
                                            限时售卖
                                        </span>
                                    )}
                                    {x.is_hot === 1 && (
                                        <span className={`${styles.activitymark}`}>
                                           最优惠
                                       </span>
                                        // <Img width={50} height={16} src={appendHost("/images/user-center/buy/youhui.png")} />
                                    )}
                                </div>
                                <div className={`${styles.vip_old_price}`}>
                                    原价{x.old_cost}
                                </div>
                            </div>
                            <div>
                                <span className={styles.red_p}>{x.cost}</span>
                                <span>元</span>
                            </div>
                        </div>
                    </Link>
                })}
            </div>
        </div>
    )
}
export default BuyCard;


// const vipItem = [
//     {
//         name: "VIP月卡",
//         originalPrice: 1000,
//         actual: 100
//     },
//     {
//         name: "VIP月卡",
//         originalPrice: 1000,
//         actual: 100
//     },
//     {
//         name: "VIP月卡",
//         originalPrice: 1000,
//         actual: 100
//     },
// ]
