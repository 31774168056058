import ImageList from "@/components/list";
import Footer from "@/components/footer";
import Pagination from "@/components/pagination";
import useAxios from "@/hooks/useAxios";
import { useParams } from "react-router";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import u, { cancelAllPreviousFetchRequests } from "@/util";
// import Back from "@/components/back";
import styles from "./styles/tese.module.css"
import Img from "@/components/img";
import { appendHost } from "@/const/append";
import Button from "@/components/button";

const CategoryTese: FC = () => {
    const { channel, id } = useParams()
    const { req } = useAxios("tese/detail", "post");
    const [reset, setReset] = useState<boolean>(false)
    const [list, setList] = useState<any[]>([])
    const [dta, setDta] = useState<any>({})

    const fetchDataList = useCallback(async (page?: number) => {
        if (!channel) return;
        const params = {
            channel,
            id,
            page: page || 1
        }
        const res = await req(params);
        if (res) {
            setDta(res?.data);
            console.log("res response", res)
            // console.log(res?.data?.list?.data)
            if (Array.isArray(res?.data?.list?.data)) {
                setList(res?.data?.list?.data)
            }
        }
    }, [channel, id])

    useEffect(() => {
        fetchDataList()
    }, [fetchDataList])

    const goExternal = useCallback(() => {
        // const __val = u.getStatsParams(false, "tese");
        // await req2({ ...__val, category: "external", name: dta?.host?.url })
        return window.open(dta?.host?.url)
    }, [dta])

    const TeseJumpEl = useCallback((btm?: boolean) => {
        return <>
            <div className={styles.ts_sin_vid}><span onClick={goExternal}>本区</span>视频由{dta?.host?.nickname}独家提供</div>
            <div className={`${styles.ts_container} ${styles.main1} ${btm ? 'mb20' : ''}`}>
                <div onClick={goExternal}>
                    <Img src={dta?.host?.logo} cls={`${styles.ts_logo}`} />
                </div>
                <div className="grid gap10">
                    <div className="fl align_center ">
                        <div className={styles.ts_title}>
                            {dta?.host?.nickname}
                        </div>

                        <Button event={goExternal} cls={`btn_sty2 ${styles.ts_btn}`}>
                            进入官网
                        </Button>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.desc}>
                        {u.dot(dta?.host?.desc)}
                    </div>
                </div>
            </div>
        </>
    }, [goExternal, dta])


    return (
        <div className="grid gap10">
            <div className="mt20">
                <div className={`mw1100 ${styles.ts_container} ${styles.main}`}>
                    <div onClick={goExternal}>
                        <Img src={appendHost(dta?.host?.logo)} cls={`${styles.ts_logo}`} />
                    </div>

                    <div>
                        <div className={styles.ts_title}>{dta?.host?.nickname}</div>
                        <div className={styles.desc}>
                            {dta?.host?.desc}
                        </div>
                    </div>

                    <div className={styles.left_cover}>
                        <Button event={goExternal} cls={`btn_sty2`}>
                            进入官网
                        </Button>
                    </div>
                </div>

                <div className={`mw1100 grid gap30 ${styles.ts}`}>

                    <div>
                        <div className="main">
                            <div className="ht">福利视频</div>
                            <div onClick={goExternal}>更多视频</div>
                        </div>

                        {TeseJumpEl(true)}

                        <ImageList tag="免费" reset={reset} channel={channel} list={list.map((x) => ({ ...x, thumb: `maomi_tese/${x.thumb}`, isLs: dta?.host?.is_landscape || 0, _info: dta?.host }))} idkey="title" idValue="id" imgUrlkey="thumb" >
                            <div className="mt5 fl gap5">
                                <Img cls={styles.ts_de_img} src={dta?.host?.logo} width={15} height={15} />
                                <div className={styles.ts_detail}>
                                    {dta?.host?.nickname}
                                </div>
                            </div>
                        </ImageList>
                    </div>
                </div>
            </div>

            <div>
                <Pagination cancelFn={cancelAllPreviousFetchRequests} lastPg={dta?.list?.last_page} total={dta?.list?.total} changeHandler={(e) => {
                    fetchDataList(e.current);
                    setReset(true);
                }} />
            </div>

            {TeseJumpEl()}

            <Footer />
        </div>
    )
}
export default CategoryTese