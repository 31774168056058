import Footer from "@/components/footer";
import Img from "@/components/img";
import { appendHost } from "@/const/append";
import CategoryHead from "@/components/categoryHead";
import useAxios from "@/hooks/useAxios";
import { useParams } from "react-router-dom";
import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import u, { cancelAllPreviousFetchRequests } from "@/util";
import styles from "./styles/detail.module.css"
import copy from 'copy-to-clipboard';
import Back from "@/components/back";
import BackBlack from "@/components/backBlack";
import { EModalTypes, modalSubject$ } from "@/hooks/useModal";
import FloatLR from "@/components/floatLR";
import useConfBase from "@/hooks/useConfBase";
import Pagination from "@/components/pagination";
import ImageList from "@/components/list";
import useCategory from "@/hooks/useCategory";

const DetailIndex: FC = () => {
    const { channel } = useParams()
    return (
        <CategoryHead showCategory>
            {channel === "cili" && <CiliDetail />}
            {channel === "yousheng" && <YoushengDetail />}
            {channel === "xiaoshuo" && <XiaoshuoDetail />}
            {channel === "meinv" && <MeinvDetail />}
            {channel === "news" && <NewsDetail />}
            {channel === "app" && <>
                <AppDetail />
                <FloatLR />
            </>}
        </CategoryHead>
    )
}
export default DetailIndex;

const CiliDetail: FC = () => {
    const { id } = useParams()
    const { req } = useAxios("cili/detail", 'post');
    const [img, setImg] = useState<any>([])

    const [data, setData] = useState<any>({})

    const fetchCiliDataList = useCallback(async () => {
        if (!id) return;
        const ciliRes = await req({ id: id });
        if (ciliRes) {
            setData(ciliRes?.data);
            // await u.fetchInBatches([`${config?.image_url}/${ciliRes?.data?.info?.thumb}.txt`], setImg)
        }
    }, [id])

    useEffect(() => {
        fetchCiliDataList()
    }, [fetchCiliDataList])

    const [config] = useConfBase();
    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <div className="grid gap10">
                    <div className={`${styles.t} ${styles.f18}`}>{data?.info?.title}</div>
                    <div className={styles.d}>
                        <Img encryptUrl={`${config?.image_url}/${data?.info?.cover}.txt`} height={474} width={500} errorImg={2} src={img[0]} />
                        <div className="grid">
                            <div className={styles.cs}><span className={styles.cv}>标签</span>：{data?.info?.tags}</div>
                            <div className={styles.cs}><span className={styles.cv}>发行日期</span>：{u.toFmt(data?.info?.publish_time * 1000)}</div>
                            <div className={styles.cs}><span className={styles.cv}>长度</span>：{data?.info?.duration}分钟</div>
                            <div className={styles.cs}><span className={styles.cv}>制造商</span>：{data?.info?.publisher || '-'}</div>
                            <div className={styles.cs}><span className={styles.cv}>系列</span>：{data?.info?.series || '-'}</div>
                            <div className={styles.cs}><span className={styles.cv}>类别</span>：{data?.info?.sub_tag || '-'}</div>
                            <div className={styles.cs}><span className={styles.cv}>演员</span>：{data?.info?.actor || '-'}</div>
                        </div>
                    </div>
                    <div className="y_scroll">
                        <div className={`${styles.t} ${styles.f16} ${styles.t_b}`}>种子下载链接</div>
                        <div className={styles.d_z}>
                            <div className={`${styles.t} ${styles.f14}`}>磁力名称</div>
                            <div className={`${styles.t} ${styles.f14}`}>档案大小</div>
                            <div className={`${styles.t} ${styles.f14}`}>分享日期</div>
                        </div>
                        {data?.info?.down_url && data?.info?.down_url.map((x: any, i: number) => {
                            return <div><div className={styles.d_z}>
                                <div>
                                    <div className="fl gap10">
                                        <div><a className={styles.al} href={x.down_url}>{x.down_url}</a></div>
                                        <div className="fl align_center">
                                            <div className={styles.hd}>高清</div>
                                            <div onClick={() => {
                                                copy(x.down_url);
                                                modalSubject$.next({
                                                    id: EModalTypes.MASK,
                                                })
                                            }} className={styles.cp}>复制</div>

                                        </div>
                                    </div>
                                    <br />
                                </div>
                                <div>{u.getGB(x.size)}</div>
                                <div>{u.toFmt(x.publish_time * 1000)}</div>
                            </div>
                                {
                                    data?.info?.down_url?.length - 1 === i && <>
                                        <div className={styles.w30}>复制磁力链接方法：1、右键磁力名称选择【复制链接地址】2、点击磁力名称后面的【复制】</div><br />
                                        <div className={styles.w30}>PS：下载工具可选择：比特彗星 比特精灵 uTorrent QBittorrent 迅雷（请您自行搜索下载）</div><br />
                                    </>
                                }
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

const YoushengDetail: FC = () => {
    const { id } = useParams()
    const { req } = useAxios("yousheng/detail", 'post');
    const ref1 = useRef<HTMLAudioElement>(null)
    const [data, setData] = useState<any>({})

    const fetchYsDataList = useCallback(async () => {
        if (!id) return;
        const ysRes = await req({ id: id });
        if (ysRes) {
            setData(ysRes?.data);
            if (ref1.current && ref1.current.currentSrc) {
                ref1.current.autoplay = true
            }
        }
    }, [id])

    useEffect(() => {
        fetchYsDataList()
    }, [])

    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <Back>{data?.info?.title}</Back>
                <div className="relative">
                    <div className="grid justify_center">
                        <div className={styles.ys_img} >
                            <Img style={{ width: "100%", height: "200px" }} src={appendHost("/images/yousheng/default.png")} />
                        </div>
                        <div className={styles.z300}>
                            <div className="text_center">
                                <Img style={{ width: "200px", height: "200px" }} src={appendHost("/images/yousheng/default.png")} />
                            </div>
                            <div>
                                <audio autoPlay={true} controls src={data?.info?.content} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

const XiaoshuoDetail: FC = () => {
    const { id } = useParams()
    const { req } = useAxios("xiaoshuo/detail", 'post');
    const [data, setData] = useState<any>({})


    const fetchYsDataList = useCallback(async () => {
        if (!id) return;
        const res = await req({ id: id });
        if (res) {
            setData(res?.data);
        }
    }, [id])

    useEffect(() => {
        fetchYsDataList()
    }, [])

    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <BackBlack hideContent={true} title={"小说详情"}></BackBlack>
                <div className={styles.xs_detail}>
                    <div className={styles.xs_title}>
                        {data?.info?.title}
                    </div>
                    <div className={styles.xs_content}>
                        {data?.info?.finish ? '已完结' : '正在连载中'}
                    </div>
                    <div className={`${styles.xs_content} flex gap10 align_center`}>
                        分类： {data?.info?.tags}
                    </div>
                    <div className={`${styles.xs_content} flex gap10 align_center`}>
                        总数： {data?.info?.content?.length || 0}
                    </div>
                    <div className={styles.text_l} dangerouslySetInnerHTML={{ __html: data?.info?.content }}></div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

const NewsDetail: FC = () => {
    const { id } = useParams()
    const { req } = useAxios("news/detail", 'post');
    const [data, setData] = useState<any>({});
    const [config] = useConfBase();
    const [encryptUrl, setEncryptUrls] = useState<any>([])

    const fetchDataList = useCallback(async () => {
        if (!id) return;
        // setImgList([])
        const res = await req({ id: id });
        if (res) {
            setData(res?.data?.info);
        }
    }, [id])

    useEffect(() => {
        fetchDataList()
    }, [])

    useEffect(() => {
        let temp = [] as any

        const myTimeout = setTimeout(() => {
            let images = document.querySelectorAll(".newsContent img") as any;
            if (images) {
                for (let i = 0; i < images.length; i++) {
                    temp.push(images[i].src)
                }
                temp = temp.map((x: string) => x.replaceAll(window.location.origin, ""));
                const urls = temp.map((x: any) => `${config?.image_url}${x}.txt`);
                setEncryptUrls(urls);
            }
        }, 2000)

        return () => {
            clearTimeout(myTimeout)
        }
    }, [config])

    useEffect(() => {
        console.log(encryptUrl)
    }, [encryptUrl])

    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <Back>新闻详情</Back>
                <div className={styles.news_t}>{data?.description}</div>
                <div className={`mt10 ${styles.news_img}`}>
                    <div className="newsContent" dangerouslySetInnerHTML={{ __html: data?.content }}></div>
                    <div>
                        {encryptUrl && encryptUrl.map((x: any, i: number) => {
                            return <Img errorImg={1} key={i} style={{ width: "100%", height: "100%" }} src={x} encryptUrl={x} />
                        })}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

const AppDetail: FC = () => {
    const { id } = useParams()
    const { req } = useAxios("appdown/detail", 'post');
    const [data, setData] = useState<any>({})

    const fetchAppDetails = useCallback(async () => {
        if (!id) return
        const res = await req({ id: id });
        if (res) {
            console.log(res?.data)
            setData(res?.data?.info)
        }
    }, [id])

    useEffect(() => {
        fetchAppDetails()
    }, [fetchAppDetails])

    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <Back>软件详情</Back>
                <div className="pad10">
                    <div className="grid gap10">
                        <div className="fl justify_between align_center">
                            <div className="fl align_center gap10">
                                <Img cls="br20" src={data?.icon} width={58} height={58} />
                                <div>
                                    <div>{data?.name}</div>
                                    <div>{data?.status}</div>
                                </div>
                            </div>
                            <div className={`point ${styles.ap_dwn}`} onClick={() => window.open(data?.web_url)}>下载</div>
                        </div>
                        <div className={styles.ap_img}>
                            {data?.sub_img?.map((x: any, i: any) => {
                                return <div key={i}>
                                    <Img style={{ width: "100%", height: "100%" }} src={x} />
                                </div>
                            })}
                        </div>
                        <div className="lb"></div>
                        <div>{data?.description}</div>
                        <div className="lb"></div>
                        <div className="mb10">
                            支持我的系统
                            <div className="fl gap10 mt10">
                                <div onClick={() => window.open(data?.and_url)}>
                                    <Img width={132} height={40} src={appendHost("/images/app-down/android.png")} />
                                </div>
                                <div onClick={() => window.open(data?.ios_url)}>
                                    <Img width={132} height={40} src={appendHost("/images/app-down/ios.png")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

const MeinvDetail: FC = () => {
    const category = useCategory()
    const { req } = useAxios("chapter/base", 'post')
    const { channel, id } = useParams()
    const [data, setData] = useState<any>({})

    const fetchDetails = useCallback(async (page?: any) => {
        if (!id || !channel) return;
        const { pname, isMain } = u.searchIsParentList(category, channel, id)
        const _val = u.searchIdByChannelAndId(category, channel, id);

        const res = await req({ id: id, channel: channel, page: page || 1 })
        if (res) {
            setData(res?.data?.info)
        }
    }, [channel, id])

    useEffect(() => {
        fetchDetails()
    }, [])

    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <BackBlack channel="meinv" row={data?.chapters} title={"图片章节"}></BackBlack>
                <div className={styles.mvd_tip}>
                    MAOMIAV精选图册，满足猫友们的喜好，每日更新
                </div>

                <ImageList channel={channel} list={data?.chapters?.data} idkey="title" idValue="id" imgUrlkey="thumb" />
            </div>
            <Pagination cancelFn={cancelAllPreviousFetchRequests} lastPg={data?.chapters?.last_page} total={data?.chapters?.total} changeHandler={ async (e) => {
                await fetchDetails(e.current);
            }} />
            <Footer />
        </div>
    )
}
