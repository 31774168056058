import ImageList from "@/components/list";
import Back from "@/components/back";
import Footer from "@/components/footer";
import Pagination from "@/components/pagination";
import useAxios from "@/hooks/useAxios";
import useCategory from "@/hooks/useCategory";
import u, { cancelAllPreviousFetchRequests } from "@/util";
import { useParams } from "react-router-dom";
import { FC, useCallback, useEffect, useState } from "react";
import ParentCategory from "@/components/parentCategory";

const CategoryPicture: FC = () => {
    const { channel, id } = useParams()
    const category = useCategory()
    const { req: req2 } = useAxios("list/base", "post");
    const { req: req3 } = useAxios("index/base", "post");
    const [dta, setDta] = useState<any>({})
    const [list, setList] = useState<any>([])
    const [parentCategoryName, setParentCategoryName] = useState<any>("")
    const [jumpId, setJumpId] = useState<any>()
    const [name, setName] = useState<string>("")
    const [isParent, setIsParent] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<number>(0);
    const [reset, setReset] = useState<boolean>(false)

    const fetchDataList = useCallback(async (page?: any) => {
        if (!channel || !id) return;
        const { pname, isMain } = u.searchIsParentList(category, channel, id)
        const _val = u.searchIdByChannelAndId(category, channel, id);
        const isChild = isMain === false
        setParentCategoryName(pname)
        setIsParent(isMain)
        setName(channel === "meinv" ? '撸撸图区' : isMain ? pname : _val?.name);
        // if (isChild && !_val?.jump_name) return;

        const p = {
            channel: channel,
            name: _val?.jump_name,
            page: page || 1
        } as any;

        // meinv 当成child且不要name
        // if (channel === 'meinv') {
        //     delete p["name"];
        // }
        // if (isMain && channel === 'tupian') {
        //     delete p["name"];
        // }
        const fn = isMain ? req3 : channel !== 'meinv'  ? req2 : req3
        const res = await fn(p);
        if (res?.data) {
            setDta(res?.data);
            if (isChild ) {
                if (channel === "meinv") {
                    setList(res?.data?.list);
                } else {
                    setList(res?.data?.list?.data);
                }
              
            } else {
                const first = res?.data?.cat_list?.[0];
                setName(first?.name)
                setJumpId(first?.id)
                setIsSelected(first?.jump_name);
                if (Array.isArray(res?.data?.list)) {
                    const selectedList = res?.data?.list?.find((x: any) => x?.cat?.jump_name === first?.jump_name)?.list || [];
                    setList(selectedList);
                }
            }
        }
        setReset(false)
    }, [category, channel, id])


    const handleClick = useCallback((row: any) => {
        setJumpId(row?.id)
        setName(row?.name)
        setIsSelected(row?.jump_name);
        if (Array.isArray(dta?.list)) {
            const selectedList = dta?.list?.find((x: any) => x?.cat?.jump_name === row?.jump_name)?.list || [];
            setList(selectedList);
        }
        setTimeout(() => {
            setReset(false)
        }, 2000)
    }, [dta])

    useEffect(() => {
        fetchDataList()
    }, [fetchDataList])
    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <Back>{parentCategoryName || name}</Back>
                {isParent && <ParentCategory list={dta?.cat_list} handleClick={handleClick} isSelected={isSelected}  />}

                {isParent && <div className="main">
                    <div className="ht">{name || ''}</div>
                    <div onClick={() => window.open(`/page/${channel}/${jumpId}`)}>更多</div>
                </div>}

                <ImageList reset={reset} channel={channel} list={list} idkey={channel === 'meinv' ? 'name' : 'title'} idValue="id" imgUrlkey="thumb" />
            </div>
            <Pagination cancelFn={cancelAllPreviousFetchRequests} lastPg={dta?.list?.last_page} total={dta?.list?.total} changeHandler={ async (e) => {
                await fetchDataList(e.current)
                setReset(true)
            }} />
            <Footer />
        </div>
    )
}
export default CategoryPicture