export const areaCodes = [
    {
        "name": "中国",
        "value": "+86"
    },
    {
        "name": "阿富汗",
        "value": "+93"
    },
    {
        "name": "阿尔巴尼亚",
        "value": "+355"
    },
    {
        "name": "阿尔及利亚",
        "value": "+213"
    },
    {
        "name": "美属萨摩亚",
        "value": "+684"
    },
    {
        "name": "安道尔",
        "value": "+376"
    },
    {
        "name": "安哥拉",
        "value": "+244"
    },
    {
        "name": "安圭拉",
        "value": "+1264"
    },
    {
        "name": "南极洲",
        "value": "+672"
    },
    {
        "name": "安提瓜和巴布达",
        "value": "+1268"
    },
    {
        "name": "阿根廷",
        "value": "+54"
    },
    {
        "name": "亚美尼亚",
        "value": "+374"
    },
    {
        "name": "阿鲁巴",
        "value": "+297"
    },
    {
        "name": "澳大利亚",
        "value": "+61"
    },
    {
        "name": "奥地利",
        "value": "+43"
    },
    {
        "name": "阿塞拜疆",
        "value": "+994"
    },
    {
        "name": "巴林",
        "value": "+973"
    },
    {
        "name": "孟加拉国",
        "value": "+880"
    },
    {
        "name": "巴巴多斯",
        "value": "+1246"
    },
    {
        "name": "白俄罗斯",
        "value": "+375"
    },
    {
        "name": "比利时",
        "value": "+32"
    },
    {
        "name": "伯利兹",
        "value": "+501"
    },
    {
        "name": "贝宁",
        "value": "+229"
    },
    {
        "name": "百慕大",
        "value": "+1441"
    },
    {
        "name": "不丹",
        "value": "+975"
    },
    {
        "name": "玻利维亚",
        "value": "+591"
    },
    {
        "name": "波黑",
        "value": "+387"
    },
    {
        "name": "博茨瓦纳",
        "value": "+267"
    },
    {
        "name": "巴西",
        "value": "+55"
    },
    {
        "name": "英属维尔京群岛",
        "value": "+1284"
    },
    {
        "name": "文莱",
        "value": "+673"
    },
    {
        "name": "保加利亚",
        "value": "+359"
    },
    {
        "name": "布基纳法索",
        "value": "+226"
    },
    {
        "name": "缅甸",
        "value": "+95"
    },
    {
        "name": "布隆迪",
        "value": "+257"
    },
    {
        "name": "柬埔寨",
        "value": "+855"
    },
    {
        "name": "喀麦隆",
        "value": "+237"
    },
    {
        "name": "加拿大",
        "value": "+1"
    },
    {
        "name": "佛得角",
        "value": "+238"
    },
    {
        "name": "开曼群岛",
        "value": "+1345"
    },
    {
        "name": "中非",
        "value": "+236"
    },
    {
        "name": "乍得",
        "value": "+235"
    },
    {
        "name": "智利",
        "value": "+56"
    },
    {
        "name": "圣诞岛",
        "value": "+61"
    },
    {
        "name": "科科斯（基林）群岛",
        "value": "+61"
    },
    {
        "name": "哥伦比亚",
        "value": "+57"
    },
    {
        "name": "科摩罗",
        "value": "+269"
    },
    {
        "name": "刚果（金）",
        "value": "+243"
    },
    {
        "name": "刚果（布）",
        "value": "+242"
    },
    {
        "name": "库克群岛",
        "value": "+682"
    },
    {
        "name": "哥斯达黎加",
        "value": "+506"
    },
    {
        "name": "科特迪瓦",
        "value": "+225"
    },
    {
        "name": "克罗地亚",
        "value": "+385"
    },
    {
        "name": "古巴",
        "value": "+53"
    },
    {
        "name": "塞浦路斯",
        "value": "+357"
    },
    {
        "name": "捷克",
        "value": "+420"
    },
    {
        "name": "丹麦",
        "value": "+45"
    },
    {
        "name": "吉布提",
        "value": "+253"
    },
    {
        "name": "多米尼克",
        "value": "+1767"
    },
    {
        "name": "多米尼加",
        "value": "+1809"
    },
    {
        "name": "厄瓜多尔",
        "value": "+593"
    },
    {
        "name": "埃及",
        "value": "+20"
    },
    {
        "name": "萨尔瓦多",
        "value": "+503"
    },
    {
        "name": "赤道几内亚",
        "value": "+240"
    },
    {
        "name": "厄立特里亚",
        "value": "+291"
    },
    {
        "name": "爱沙尼亚",
        "value": "+372"
    },
    {
        "name": "埃塞俄比亚",
        "value": "+251"
    },
    {
        "name": "福克兰群岛（马尔维纳斯）",
        "value": "+500"
    },
    {
        "name": "法罗群岛",
        "value": "+298"
    },
    {
        "name": "斐济",
        "value": "+679"
    },
    {
        "name": "芬兰",
        "value": "+358"
    },
    {
        "name": "法国",
        "value": "+33"
    },
    {
        "name": "法属圭亚那",
        "value": "+594"
    },
    {
        "name": "法属波利尼西亚",
        "value": "+689"
    },
    {
        "name": "加蓬",
        "value": "+241"
    },
    {
        "name": "格鲁吉亚",
        "value": "+995"
    },
    {
        "name": "德国",
        "value": "+49"
    },
    {
        "name": "加纳",
        "value": "+233"
    },
    {
        "name": "直布罗陀",
        "value": "+350"
    },
    {
        "name": "希腊",
        "value": "+30"
    },
    {
        "name": "格陵兰",
        "value": "+299"
    },
    {
        "name": "格林纳达",
        "value": "+1473"
    },
    {
        "name": "瓜德罗普",
        "value": "+590"
    },
    {
        "name": "关岛",
        "value": "+1671"
    },
    {
        "name": "危地马拉",
        "value": "+502"
    },
    {
        "name": "根西岛",
        "value": "+1481"
    },
    {
        "name": "几内亚",
        "value": "+224"
    },
    {
        "name": "几内亚比绍",
        "value": "+245"
    },
    {
        "name": "圭亚那",
        "value": "+592"
    },
    {
        "name": "海地",
        "value": "+509"
    },
    {
        "name": "梵蒂冈",
        "value": "+379"
    },
    {
        "name": "洪都拉斯",
        "value": "+504"
    },
    {
        "name": "香港",
        "value": "+852"
    },
    {
        "name": "匈牙利",
        "value": "+36"
    },
    {
        "name": "冰岛",
        "value": "+354"
    },
    {
        "name": "印度",
        "value": "+91"
    },
    {
        "name": "印度尼西亚",
        "value": "+62"
    },
    {
        "name": "伊朗",
        "value": "+98"
    },
    {
        "name": "伊拉克",
        "value": "+964"
    },
    {
        "name": "爱尔兰",
        "value": "+353"
    },
    {
        "name": "以色列",
        "value": "+972"
    },
    {
        "name": "意大利",
        "value": "+39"
    },
    {
        "name": "牙买加",
        "value": "+1876"
    },
    {
        "name": "日本",
        "value": "+81"
    },
    {
        "name": "约旦",
        "value": "+962"
    },
    {
        "name": "哈萨克斯坦",
        "value": "+73"
    },
    {
        "name": "肯尼亚",
        "value": "+254"
    },
    {
        "name": "基里巴斯",
        "value": "+686"
    },
    {
        "name": "朝鲜",
        "value": "+850"
    },
    {
        "name": "韩国",
        "value": "+82"
    },
    {
        "name": "科威特",
        "value": "+965"
    },
    {
        "name": "吉尔吉斯斯坦",
        "value": "+996"
    },
    {
        "name": "老挝",
        "value": "+856"
    },
    {
        "name": "拉脱维亚",
        "value": "+371"
    },
    {
        "name": "黎巴嫩",
        "value": "+961"
    },
    {
        "name": "莱索托",
        "value": "+266"
    },
    {
        "name": "利比里亚",
        "value": "+231"
    },
    {
        "name": "利比亚",
        "value": "+218"
    },
    {
        "name": "列支敦士登",
        "value": "+423"
    },
    {
        "name": "立陶宛",
        "value": "+370"
    },
    {
        "name": "卢森堡",
        "value": "+352"
    },
    {
        "name": "澳门",
        "value": "+853"
    },
    {
        "name": "前南马其顿",
        "value": "+389"
    },
    {
        "name": "马达加斯加",
        "value": "+261"
    },
    {
        "name": "马拉维",
        "value": "+265"
    },
    {
        "name": "马来西亚",
        "value": "+60"
    },
    {
        "name": "马尔代夫",
        "value": "+960"
    },
    {
        "name": "马里",
        "value": "+223"
    },
    {
        "name": "马耳他",
        "value": "+356"
    },
    {
        "name": "马绍尔群岛",
        "value": "+692"
    },
    {
        "name": "马提尼克",
        "value": "+596"
    },
    {
        "name": "毛里塔尼亚",
        "value": "+222"
    },
    {
        "name": "毛里求斯",
        "value": "+230"
    },
    {
        "name": "马约特",
        "value": "+269"
    },
    {
        "name": "墨西哥",
        "value": "+52"
    },
    {
        "name": "密克罗尼西亚",
        "value": "+691"
    },
    {
        "name": "摩尔多瓦",
        "value": "+373"
    },
    {
        "name": "摩纳哥",
        "value": "+377"
    },
    {
        "name": "蒙古",
        "value": "+976"
    },
    {
        "name": "蒙特塞拉特",
        "value": "+1664"
    },
    {
        "name": "摩洛哥",
        "value": "+212"
    },
    {
        "name": "莫桑比克",
        "value": "+258"
    },
    {
        "name": "纳米尼亚",
        "value": "+264"
    },
    {
        "name": "瑙鲁",
        "value": "+674"
    },
    {
        "name": "尼泊尔",
        "value": "+977"
    },
    {
        "name": "荷兰",
        "value": "+31"
    },
    {
        "name": "荷属安的列斯",
        "value": "+599"
    },
    {
        "name": "新喀里多尼亚",
        "value": "+687"
    },
    {
        "name": "新西兰",
        "value": "+64"
    },
    {
        "name": "尼加拉瓜",
        "value": "+505"
    },
    {
        "name": "尼日尔",
        "value": "+227"
    },
    {
        "name": "尼日利亚",
        "value": "+234"
    },
    {
        "name": "纽埃",
        "value": "+683"
    },
    {
        "name": "诺福克岛",
        "value": "+6723"
    },
    {
        "name": "北马里亚纳",
        "value": "+1"
    },
    {
        "name": "挪威",
        "value": "+47"
    },
    {
        "name": "阿曼",
        "value": "+968"
    },
    {
        "name": "巴基斯坦",
        "value": "+92"
    },
    {
        "name": "帕劳",
        "value": "+680"
    },
    {
        "name": "巴拿马",
        "value": "+507"
    },
    {
        "name": "巴布亚新几内亚",
        "value": "+675"
    },
    {
        "name": "巴拉圭",
        "value": "+595"
    },
    {
        "name": "秘鲁",
        "value": "+51"
    },
    {
        "name": "菲律宾",
        "value": "+63"
    },
    {
        "name": "波兰",
        "value": "+48"
    },
    {
        "name": "葡萄牙",
        "value": "+351"
    },
    {
        "name": "波多黎各",
        "value": "+1809"
    },
    {
        "name": "卡塔尔",
        "value": "+974"
    },
    {
        "name": "留尼汪",
        "value": "+262"
    },
    {
        "name": "罗马尼亚",
        "value": "+40"
    },
    {
        "name": "俄罗斯",
        "value": "+7"
    },
    {
        "name": "卢旺达",
        "value": "+250"
    },
    {
        "name": "圣赫勒拿",
        "value": "+290"
    },
    {
        "name": "圣基茨和尼维斯",
        "value": "+1869"
    },
    {
        "name": "圣卢西亚",
        "value": "+1758"
    },
    {
        "name": "圣皮埃尔和密克隆",
        "value": "+508"
    },
    {
        "name": "圣文森特和格林纳丁斯",
        "value": "+1784"
    },
    {
        "name": "萨摩亚",
        "value": "+685"
    },
    {
        "name": "圣马力诺",
        "value": "+378"
    },
    {
        "name": "圣多美和普林西比",
        "value": "+239"
    },
    {
        "name": "沙特阿拉伯",
        "value": "+966"
    },
    {
        "name": "塞内加尔",
        "value": "+221"
    },
    {
        "name": "塞尔维亚和黑山",
        "value": "+381"
    },
    {
        "name": "塞舌尔",
        "value": "+248"
    },
    {
        "name": "塞拉利",
        "value": "+232"
    },
    {
        "name": "新加坡",
        "value": "+65"
    },
    {
        "name": "斯洛伐克",
        "value": "+421"
    },
    {
        "name": "斯洛文尼亚",
        "value": "+386"
    },
    {
        "name": "所罗门群岛",
        "value": "+677"
    },
    {
        "name": "索马里",
        "value": "+252"
    },
    {
        "name": "南非",
        "value": "+27"
    },
    {
        "name": "西班牙",
        "value": "+34"
    },
    {
        "name": "斯里兰卡",
        "value": "+94"
    },
    {
        "name": "苏丹",
        "value": "+249"
    },
    {
        "name": "苏里南",
        "value": "+597"
    },
    {
        "name": "斯瓦尔巴岛和扬马延岛",
        "value": "+47"
    },
    {
        "name": "斯威士兰",
        "value": "+268"
    },
    {
        "name": "瑞典",
        "value": "+46"
    },
    {
        "name": "瑞士",
        "value": "+41"
    },
    {
        "name": "叙利亚",
        "value": "+963"
    },
    {
        "name": "台湾",
        "value": "+886"
    },
    {
        "name": "塔吉克斯坦",
        "value": "+992"
    },
    {
        "name": "坦桑尼亚",
        "value": "+255"
    },
    {
        "name": "泰国",
        "value": "+66"
    },
    {
        "name": "巴哈马",
        "value": "+1242"
    },
    {
        "name": "冈比亚",
        "value": "+220"
    },
    {
        "name": "多哥",
        "value": "+228"
    },
    {
        "name": "托克劳",
        "value": "+690"
    },
    {
        "name": "汤加",
        "value": "+676"
    },
    {
        "name": "特立尼达和多巴哥",
        "value": "+1868"
    },
    {
        "name": "突尼斯",
        "value": "+216"
    },
    {
        "name": "土耳其",
        "value": "+90"
    },
    {
        "name": "土库曼斯坦",
        "value": "+993"
    },
    {
        "name": "特克斯和凯科斯群岛",
        "value": "+1649"
    },
    {
        "name": "图瓦卢",
        "value": "+688"
    },
    {
        "name": "乌干达",
        "value": "+256"
    },
    {
        "name": "乌克兰",
        "value": "+380"
    },
    {
        "name": "阿拉伯联合酋长国",
        "value": "+971"
    },
    {
        "name": "英国",
        "value": "+44"
    },
    {
        "name": "美国",
        "value": "+1"
    },
    {
        "name": "乌拉圭",
        "value": "+598"
    },
    {
        "name": "乌兹别克斯坦",
        "value": "+998"
    },
    {
        "name": "瓦努阿图",
        "value": "+678"
    },
    {
        "name": "委内瑞拉",
        "value": "+58"
    },
    {
        "name": "越南",
        "value": "+84"
    },
    {
        "name": "美属维尔京群岛",
        "value": "+1340"
    },
    {
        "name": "瓦利斯和富图纳",
        "value": "+681"
    },
    {
        "name": "也门",
        "value": "+967"
    },
    {
        "name": "赞比亚",
        "value": "+260"
    },
    {
        "name": "津巴布韦",
        "value": "+263"
    }
]