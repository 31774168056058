import useConfBase from "@/hooks/useConfBase";
import { FC, useCallback, useEffect, useState } from "react";
import "./styles/floatLR.module.css"
import Img from "./img";
import styles from "@/components/styles/floatLR.module.css"
import u, { ELocalKey } from "@/util";

const FloatLR: FC = () => {
    const [config, updateConfBase, getData] = useConfBase();
    const [img, setImg] = useState<any[]>([]);
    const [hide, setHide] = useState<number>(0);
    const [close, setClose] = useState<string[]>([])

    useEffect(() => {
        if(!localStorage.getItem(ELocalKey._LOCK_FORMAT) || localStorage.getItem(ELocalKey._FORMAT) === ''){
            // 在hooks里一直被使用，需要锁
        getData(4).then((res: any) => {
                                localStorage.setItem(ELocalKey._LOCK_FORMAT, '1'); 
                if (Array.isArray(res?.zuoyoupiaofu)) {
                    setImg(res.zuoyoupiaofu)
                }
            })
        }else{
            if(localStorage.getItem(ELocalKey._FORMAT)){
                const data =JSON.parse(u.decrypt(localStorage.getItem(ELocalKey._FORMAT)));
                if (Array.isArray(data?.zuoyoupiaofu)) {
                    setImg(data.zuoyoupiaofu)
                }
            }
        }
    }, [config])

    const onScroll = useCallback(() => {
        let _n = 1000;
        const _l = window.location.href
        if (_l.indexOf("video") >= 0) {
            _n = 2500
        }
        if (_l.indexOf("detail") >= 0) {
            _n = 2500
        }
        const v = window.scrollY > _n;
        setHide(window.scrollY);
        if (!v) {
            setClose(close)
        }
    }, [close])

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    }, [onScroll]);

    const updateClose = useCallback((val: any) => {
        setClose([...close, val])
    }, [close])

    const getAddiotionalHideCondition = useCallback(() => {
        if (u.isPermenantVip()) {
            return false;
        }
        // if (window.location.href.indexOf("page") >= 0) {
        //     return false
        // }
        if (u.hideAdsOnCertainPages()) {
            return false;
        } 
        if (hide > 1000) {
            return true
        }
        return false
    }, [hide])

    return (
        <div className={styles.main}>
            { getAddiotionalHideCondition() && <>
                <div>
                    {img.slice(0, 3).map((x, i) => {
                        const id = `t_${i}`
                        return <div key={i} className="relative">
                            {
                                !close.includes(id) && <>
                                    <div onClick={() => window.open(x.url)}>
                                        <Img cls={styles.fl_img} src={x.img} />
                                    </div>
                                    <div onClick={() => updateClose(id)} className={styles.xmark}>x</div>
                                </>
                            }
                        </div>
                    })}
                </div>
                <div >
                    {img.slice(3, 6).map((x, i) => {
                        const id = `b_${i}`
                        return <div key={id} className="relative">
                            {
                                !close.includes(id) && <>
                                    <div onClick={() => window.open(x.url)}>
                                        <Img cls={styles.fl_img} src={x.img} />
                                    </div>
                                    <div onClick={() => updateClose(id)} className={styles.xmark}>x</div>
                                </>
                            }
                        </div>
                    })}
                </div>
            </>}
        </div>
    )
}
export default FloatLR