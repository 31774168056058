import useModal, { EModalTypes, IModal, modalSubject$ } from "@/hooks/useModal";
import { FC, useCallback, useEffect } from "react";
import styles from "@/components/styles/modal.module.css"
import zipModalStyles from "@/components/styles/downloadZip.module.css"
import Img from "../img";
import { Link } from "react-router-dom";
import Button from "../button";
import u, { EVIPtype } from "@/util";
import { encode, } from 'js-base64';
import copy from "copy-to-clipboard";

const wh = { height: "83%", width: "100%" }
var sprintf = require('sprintf-js').sprintf;

const DownloadZip: FC<IModal> = () => {
    const [modalData, closeModal] = useModal();
    const copyVal = useCallback((val: any) => {
        // if (!u.isMobile()) return;
        copy(val);
        modalSubject$.next({
            id: EModalTypes.MASK,
            child: "您已成功复制下载链接～"
        })
    }, [])

    return (
        modalData?.show && modalData?.id ===  EModalTypes.DLZIP && <div className={styles.common}>
            <div className={`${styles.body} zip_modal`}>
                <div onClick={() => {
                   closeModal()
                }} className={styles.close}>X</div>
                <div className={`${styles.content} ${zipModalStyles.content}`}>    
                    <div className={`${styles.content_title} ${zipModalStyles.titleMd}`}>下载捆绑包</div>
                    <div className={`${zipModalStyles.btm}`}>
                        {/* <Link to={`/user/buy`}> */}
                         <div>
                            <div className="fl gap10 align_center">
                                <div className={zipModalStyles.gy}>
                                    {sprintf(`thunder://%s`, encode(sprintf('AA%sZZ', modalData?.data?.data?.url)))}
                                </div>
                                <Button cls="btn_dl_sty point" event={() => { copyVal(sprintf(`thunder://%s`, encode(sprintf('AA%sZZ', modalData?.data?.data?.url)))) }}>迅雷下载</Button>                            
                            </div>
                         </div>
                        {/* </Link> */}

                        <div>
                             <div className="fl gap10 align_center">
                                <div className={zipModalStyles.gy}>
                                    {modalData?.data?.data?.url}
                                </div>
                                <Button cls="btn_dl_sty point" event={() => { copyVal(modalData?.data?.data?.url) }}>FDM下载</Button>                            
                            </div>
                         </div>
                        {/* <Link to="/page/vip/57"> */}
                        {/* </Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DownloadZip;