import React, { FC, useCallback, useState } from "react";
import Button from "@/components/button";
import Input from "@/components/input";
import Img from "@/components/img";
import useAxios from "@/hooks/useAxios";
import useFlipFlop from "@/hooks/useFlipFlop";
import useUpdateForm from "@/hooks/useUpdateForm";
import useValidation from "@/hooks/useValidation";
import { vID } from "@/const/validate.const";
import { Link } from "react-router-dom";
import styles from "./styles/register.module.css";
import u from "@/util";
import { modalSubject$, EModalTypes } from "@/hooks/useModal";

const Register: FC = () => {
  const [formData, setFormData] = useState<any>({
    username: "",
    password: "",
    password_confirmation: "", // Add email field
    email: "",
  });

  const [flipText, flipHander] = useFlipFlop("確定", "注册中...");
  const { req } = useAxios("referral/register", 'post');
  const updateForm = useUpdateForm(setFormData);

  const [isValidSubmit, msgStatus] = useValidation(formData, {
    username: [vID.USERNAME_EMPTY, vID.MIN_TEXT_6_12],
    password: [vID.PWD_EMPTY, vID.MIN_TEXT_8_20],
    password_confirmation: [vID.PWD2_EMPTY, vID.PWD_PWD2_NOT_MATCH],
    email: [vID.EMAIL_FORMAT], // Add email validation

  }, "password");

  const register = useCallback(async () => {
    if (!isValidSubmit) return;
    flipHander(1);
    const res = await req(formData);

    if (res?.code === 0) {
      modalSubject$.next({
        id: EModalTypes.NORMAL,
        innerTitle: "你的会员账号已创建",
        btn: true,
        event: () => {
          flipHander(0);
          // Do something with userInfo if needed
          // TODO: after success
          // u.open("/user/buy");
        }
      });
    } else {
      console.log('res', res);
      if(res){
        modalSubject$.next({
          id: EModalTypes.NORMAL,
          innerTitle: res?.message,
          btn: true,
          event: () => flipHander(0)
        });
      }else{
        modalSubject$.next({
          id: EModalTypes.NORMAL,
          innerTitle: "注册失败",
          btn: true,
          event: () => flipHander(0)
        });
      }
    }
  }, [isValidSubmit, flipHander, formData]);

  return (
    
    <div className="">
              <div className="mx-4 py-5">     
          <div className={`grid ${styles.regplcholder} mb-2`}>
            <div className="text-right my-auto pr-2">
              <span className="text-red-600">*</span>
              账号 :
            </div>
            <Input 
              errMsg={msgStatus[0]} 
              value={formData.username} 
              onChange={(e) => updateForm({ ...formData, username: e })} 
              line 
              placeholder="输入用账号" 
            />
          </div>
          <div className={`grid ${styles.regplcholder} mb-2`}>
          <div className="text-right my-auto pr-2">
              <span className="text-red-600">*</span>
              密码 :
            </div>
            <Input 
              errMsg={msgStatus[1]} 
              value={formData.password} 
              onChange={(e) => updateForm({ ...formData, password: e })} 
              line 
              type="password" 
              placeholder="请输入密码" 
            />
          </div>
          <div className={`grid ${styles.regplcholder} mb-2`}>
          <div className="text-right my-auto pr-2">
              <span className="text-red-600">*</span>
              确认密码 :
            </div>
            <Input 
              errMsg={msgStatus[2]} 
              value={formData.password_confirmation} 
              onChange={(e) => updateForm({ ...formData, password_confirmation: e })} 
              type="password" 
              line 
              placeholder="请再次输入密码" 
            />
          </div>
          <div className={`grid ${styles.regplcholder} mb-2`}>
          <div className="text-right my-auto pr-2">
              <span className="text-red-600">*</span>
              邮箱 :
            </div>
            <Input 
              errMsg={msgStatus[3]} 
              value={formData.email} 
              onChange={(e) => updateForm({ ...formData, email: e })} 
              line 
              placeholder="请输入邮箱" 
            />
          </div>
        </div>
        <div className="py-7 text-center">
          <Button event={register} cls={`btn_sty7`}>
            <div className="a_w" >{flipText}</div>
          </Button>
        </div>
      {/* Your registration form */}

    </div>
  );
};

export default Register;
