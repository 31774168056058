export const getQuestionToken = (data: {
    email: string,
    password: string
}) => ({
    url: "auth/token",
    method: "POST",
    data
})

export const postQuestion = (data: {
    project_id: number,
    feedback_question_id: number,
    site: string,
    comment: string,
    place: string,
    network: string,
    like: string,
    domain: string
}) => ({
    url: "/feedback",
    method: "POST",
    data
})

export const getQuestionlist = (data: {
    token: string
}) => ({
    url: "feedback/question",
    method: "get",
    data
})

export const getQuestionDetail = (data: {
    token: string,
    question: string
}) => ({
    url: `feedback/question/${data.question}`,
    method: "get",
    data
})

export const postQuestionDetail = (data: {
    token: string,
    project_id: number,
    feedback_question_id?: string,
    site: string,
    option: string,
    comment: string,
    place: string,
    network: string,
    like: number,
    domain: string
}) => ({
    url: `feedback`,
    method: "post",
    data
})

// 统计2次开发
export const postQesStats = (data: {
    token: string,
    project_id: number,
    site: string,
    page?: string,
    content?: string, // 内容，可以空。如果是视频，把视频标题放在这里
    category: string,
    type: "link" | "vdep", // 点击的类型，可以是link或者video
    domain: string
}) => ({
    url: "statistics",
    method: "post",
    data
})