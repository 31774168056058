import React, { useState, useEffect } from 'react';  // Import useState and useEffect
import ReactDOM from 'react-dom/client';
import './index.css';
import { router } from "./routes"
import { RouterProvider } from "react-router-dom";
import 'normalize.css';
import '@/global.css'
import 'swiper/css';
import Global from './components/global';
import Hls from 'hls.js';
import { Helmet } from "react-helmet";
import u from './util';
// import u from './util';

const w = window as any
w.Hls = Hls;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if(!localStorage.getItem('test_mode')){
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
  }
}

declare const window: any;

// added to window variables
let mmList = window.mmList;
let goodsList = window.goodsList;
let qixia = window.qixia;
let lboxayerList = window.lboxayerList;

(function(){
  var keys = Object.keys(window).join();

  setInterval(function(){
    var newKeys = Object.keys(window).join();

    // detect new added window variables
    if (newKeys !== keys) {
      // console.log('`window` changd', newKeys);
      if(newKeys.includes("mmList")){
        localStorage.setItem("__MM", JSON.stringify(window.mmList));
      }
      if(newKeys.includes("goodsList")){
        localStorage.setItem("__GD", JSON.stringify(window.goodsList));
      }
      if(newKeys.includes("qixia")){
        localStorage.setItem("__QX", JSON.stringify(window.qixia));
      }
      if(newKeys.includes("lboxayerList")){
        localStorage.setItem("__BXL", JSON.stringify(window.lboxayerList));
      }
      keys = newKeys;
    }
  }, 100);

})();

// Main App component to manage Global loading state
const App = () => {
  const [globalLoaded, setGlobalLoaded] = useState(false);

  // Callback for when Global component finishes loading
  const handleGlobalLoad = () => {
    setGlobalLoaded(true);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{window?.location?.host}</title>
        <script async src={u.getAppScriptResources(1)}></script>
        <script async src={u.getAppScriptResources(2)}></script>
        <script async src={u.getAppScriptResources(3)}></script>
        <script async src={u.getAppScriptResources(4)}></script>
        <script async src={u.getAppScriptResources(5)} data-website-id={u.getAppScriptResources(6)}></script>
      </Helmet>

      {/* Render Global component, pass the onLoad callback */}
      <Global onLoad={handleGlobalLoad} />

      {/* Render RouterProvider only when Global has finished loading */}
      {globalLoaded && (
        <RouterProvider router={router} />
      )}
    </div>
  );
};

// Render the App
root.render(<App />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
