import ImageList from "@/components/list";
import Footer from "@/components/footer";
import Pagination from "@/components/pagination";
import useAxios from "@/hooks/useAxios";
import useCategory from "@/hooks/useCategory";
import { useParams } from "react-router";
import React, { FC, useCallback, useEffect, useState } from "react"
import u, { cancelAllPreviousFetchRequests } from "@/util";
import Back from "@/components/back";
import ParentCategory from "@/components/parentCategory";

const CategoryVideo: FC = () => {
    const { channel, id } = useParams()
    const category = useCategory()
    const { req: req2 } = useAxios("list/base", "post");
    const { req: req3 } = useAxios("index/base", "post");
    const [dta, setDta] = useState<any>({})
    const [list, setList] = useState<any>([])
    const [name, setName] = useState<string>("")
    const [parentCategoryName, setParentCategoryName] = useState<any>("");
    const [jumpId, setJumpId] = useState<any>()
    const [isParent, setIsParent] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<number>(0);
    const [reset, setReset] = useState<boolean>(false)
    const { req: reqStats } = useAxios("index/statistics", "post", true);


    const handleClick = useCallback((row: any) => {
        setJumpId(row?.id)
        setName(row?.name)
        setIsSelected(row?.jump_name);
        const selectedList = dta?.list?.find((x: any) => x?.cat?.jump_name === row?.jump_name)?.list || [];
        setList(selectedList);
        setTimeout(() => {
            setReset(false)
        }, 2000)
    }, [dta])

    const fetchDataList = useCallback(async (page?: number) => {
        if (!channel || !id) return;
        const { pname, isMain } = u.searchIsParentList(category, channel, id)
        const _val = u.searchIdByChannelAndId(category, channel, id);
        const isChild = isMain === false;
        setParentCategoryName(pname)
        setIsParent(isMain)
        setName(isMain ? pname : _val?.name);
        if (isChild && !_val?.jump_name) return;

        const p = {
            channel: channel,
            name: _val?.jump_name,
            page: page || 1
        } as any
        if (isMain) {
            delete p["name"];
            delete p["page"];
        }
        const fn = isMain ? req3 : req2;
        const res = await fn(p);
        if (res?.data) {
            setDta(res?.data);
            if (isChild) {
                // console.log(res, p, category, 'asd');
                setList(res?.data?.list?.data)
            } else {
                const first = res?.data?.cat_list?.[0];
                setName(first?.name)
                setJumpId(first?.id)
                setIsSelected(first?.jump_name);
                const selectedList = res?.data?.list?.find((x: any) => x?.cat?.jump_name === first?.jump_name)?.list || [];
                setList(selectedList);
            }
        }
        setReset(false)
    }, [category, channel, id])

    useEffect(() => {
        fetchDataList()
    }, [fetchDataList])

    const checkNameKey = useCallback(() => {
        // nvyou 专区字段判断
        if (id == "12") {
            return "name"
        }
        return "title"
    }, [id])

    const handleMore = useCallback((channel: any, jumpId: any, realName: any) => {
        hitStatis(channel, realName)
        return window.open(`/page/${channel}/${jumpId}`)
        
    }, [category,])

    const hitStatis = useCallback(async (channel: any, realName: any) => {
            const __val = u.getStatsParams(false, "index", channel + '-更多-' + realName, "") as any;
            if (__val?.c) {
                __val["c"] += 1;
            }
            reqStats(__val)
    }, [])

    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <Back>{parentCategoryName || name}</Back>
                {isParent && <ParentCategory list={dta?.cat_list} handleClick={handleClick} isSelected={isSelected} />}

                {isParent && <div className="main">
                    <div className="ht">{name || ''}</div>
                    <div onClick={() => handleMore(channel, jumpId, name || '')}>更多</div>
                </div>}
                <ImageList reset={reset} channel={channel} list={list} idkey={checkNameKey()} idValue="id" imgUrlkey="thumb" />
            </div>

            <Pagination cancelFn={cancelAllPreviousFetchRequests} lastPg={dta?.list?.last_page} total={dta?.list?.total} changeHandler={ async (e) => {
                await fetchDataList(e.current);
                setReset(true);
            }} />
            <Footer />
        </div>
    )
}
export default CategoryVideo