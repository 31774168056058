import { FC } from "react";

interface IButton {
    cls: string;
    children: React.ReactNode;
    event: (e: any) => void; 
}

const Button: FC<IButton> = ({ cls, children, event }) => {
    return <>
        <button className={cls} onClick={event}>
            {children}
        </button>
    </>
}
export default Button;