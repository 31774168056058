type Props ={
    id? : string;  // this id needed if one page reuse same icon (id cannot duplicate)
}
const Yingpian= (props : Props)=>(
<svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_20_539)">
<path d="M16.1331 22.7857H2.88086C1.29086 22.784 0.00171429 21.4781 0 19.8671V5.13285C0.00171429 3.52228 1.29043 2.21685 2.88086 2.21428H16.1331C17.6923 2.20571 18.9703 3.46571 19.0041 5.04456V5.08228C19.005 5.09942 19.005 5.11742 19.0041 5.13499V8.57942L22.0611 6.97485C22.2568 6.85812 22.4802 6.79607 22.708 6.79517C22.9358 6.79426 23.1597 6.85454 23.3563 6.9697C23.5541 7.08621 23.7177 7.25274 23.8307 7.45256C23.9437 7.65239 24.0021 7.87844 24 8.10799V16.958C23.9987 17.6814 23.4201 18.2677 22.7061 18.2686C22.4805 18.2706 22.2584 18.2118 22.0633 18.0984L19.0059 16.4938V19.8667C19.0078 19.8957 19.0078 19.9247 19.0059 19.9537V20.0201C18.9296 21.5707 17.6657 22.7874 16.1331 22.7857ZM5.14071 5.64285C4.80303 5.64313 4.46871 5.70992 4.15683 5.83941C3.84496 5.96889 3.56165 6.15854 3.32307 6.39752C3.08449 6.6365 2.89531 6.92013 2.76635 7.23221C2.63738 7.5443 2.57115 7.87873 2.57143 8.21642C2.57171 8.5541 2.6385 8.88843 2.76799 9.2003C2.89747 9.51217 3.08712 9.79549 3.3261 10.0341C3.56508 10.2726 3.84871 10.4618 4.16079 10.5908C4.47288 10.7198 4.80732 10.786 5.145 10.7857C5.82699 10.7851 6.48081 10.5137 6.96265 10.031C7.44448 9.5484 7.71485 8.89412 7.71429 8.21213C7.71372 7.53015 7.44225 6.87632 6.95962 6.39449C6.47698 5.91265 5.8227 5.64228 5.14071 5.64285Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_20_539">
<rect width="24" height="24" fill="currentColor" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>

);
export default Yingpian;