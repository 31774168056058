import Img from "@/components/img";
import { appendHost } from "@/const/append";

import useAxios from "@/hooks/useAxios";
import { FC, useCallback, useEffect, useState } from "react";
import enterStyles from "./styles/enter.module.css"
import ScrollToTop from "@/components/scrollTop";
import u, { ELocalKey } from "@/util";
import { PROD_SITE } from "@/const/prod.const";
import useConfBase from "@/hooks/useConfBase";
import { useParams } from "react-router-dom";
import styles from "./styles/landing.module.css";
import Register from "./registrationform"
import Modal from "./Modal"

function LandingPage() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col title_bg">
      {/* Top Banner Menu */}
      <nav className="bg-white w-full">
        <div className="mx-auto flex justify-between mw1100 px-2 lg:px-0">
          <div className="flex">
            <img
              src="./images/icon/logo.png"
              alt="Avatar"
              className="rounded col-span-1 self-center pb-3 pt-4 pr-8"
            />
            {/* 
            <div className="flex  border-b-4 border-black rounded-tl-xl rounded-tr-xl pb-3 pt-4 items-center ">
              <img src="/assets/img/icons/info.png" alt="Avatar" className="hidden lg:block rounded col-span-1 self-center" />
              <p className="text-black font-semibold pl-1 hidden lg:block">广告赚钱联盟</p>
            </div> 
            */}
          </div>
          <div className="pt-4 pb-4 flex gap-4">
            <button
              onClick={() => setOpen(true)}
              className="h-10 w-auto lg:w-40 px-2 lg:px-0 text-white rounded-lg bg-red-500 flex items-center justify-center gap-2"
            >
              <img
                src="./images/icon/join.png"
                alt="Join"
                className="hidden lg:block rounded col-span-1 self-center"
              />
              <p>申请加入</p>
            </button>

            <button className="h-10 w-auto lg:w-40 px-2 lg:px-0 text-white rounded-lg bg-blue-500 flex items-center justify-center gap-2">
              <img
                src="./images/icon/login.png"
                alt="Login"
                className="hidden lg:block rounded col-span-1 self-center"
              />
              <p>登入推广后台</p>
            </button>
          </div>
        </div>
      </nav>

      <div className="mw1100">
      <div className={`${styles.banner_bg} h-[35rem] lg:h-64 w-full rounded-xl mt-2`}></div>
      <div className={`grid ${styles.balancegrid} items-center justify-center mt-8 mb-6 text-center`}>
      <div className="mx-auto">
            <img src="./images/icon/left.png" alt="left" />
          </div>

          <div>
            <p className="text-3xl font-semibold">加盟流程</p>
          </div>

          <div className="mx-auto">
            <img src="./images/icon/right.png" alt="right" />
          </div>
        </div>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Register />
        </Modal>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 px-2 lg:px-0 text-center">
          <div className="bg-white p-6 rounded-xl">
          <div className="relative">
    <div className="absolute text-xl dark:text-white -translate-y-8 dark:bg-red-400 w-10 h-10 rounded-full"
         style={{ top: '50%', left: '50%', transform: 'translate(-50%, -100%)' }}>
      <p className="pt-1">1</p>
    </div>
  </div>
            <img src="./images/icon/step1.png" className="mx-auto" alt="step1" />
            <p className="text-black font-semibold text-lg mx-auto">加入猫咪AV联盟计划</p>
            <div className="text-black text-sm mt-3">
              点选
              <button className={`${styles.pinktext} text-sm underline px-0.5`} onClick={() => setOpen(true)}>立即申请加盟</button>
              , 填写并完成表单
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl">
          <div className="relative">
    <div className="absolute text-xl dark:text-white -translate-y-8 dark:bg-red-400 w-10 h-10 rounded-full"
         style={{ top: '50%', left: '50%', transform: 'translate(-50%, -100%)' }}>
      <p className="pt-1">2</p>
    </div>
  </div>
            <img src="./images/icon/step2.png" className="mx-auto" alt="step2" />
            <p className="text-black font-semibold text-lg mx-auto">联系客服</p>
            <p className="text-black text-sm mx-auto mt-3">登入后请点选在线客服</p>
          </div>
          <div className="bg-white p-6 rounded-xl">
          <div className="relative">
    <div className="absolute text-xl dark:text-white -translate-y-8 dark:bg-red-400 w-10 h-10 rounded-full"
         style={{ top: '50%', left: '50%', transform: 'translate(-50%, -100%)' }}>
      <p className="pt-1">3</p>
    </div>
  </div>
            <img src="./images/icon/step3.png" className="mx-auto" alt="step3" />
            <p className="text-black font-semibold text-lg mx-auto">取得猫咪AV专属推广网址/App</p>
            <p className="text-black text-sm mt-3">联盟网站均可申请专属 APP / 二维码级独立专属网站</p>
          </div>
          <div className="bg-white p-6 rounded-xl">
          <div className="relative">
    <div className="absolute text-xl dark:text-white -translate-y-8 dark:bg-red-400 w-10 h-10 rounded-full"
         style={{ top: '50%', left: '50%', transform: 'translate(-50%, -100%)' }}>
      <p className="pt-1">4</p>
    </div>
  </div>
            <img src="./images/icon/step4.png" className="mx-auto" alt="step4" />
            <p className="text-black font-semibold text-lg mx-auto">行销推广，奖金入袋</p>
            <p className="text-black text-sm mt-3">推广您的联盟专属网址 & APP 高额分润奖金轻松入袋</p>
          </div>
        </div>
        <div className={`grid ${styles.balancegrid} items-center justify-center mt-8 mb-6 text-center`}>
        <div className="mx-auto">
  <img src="./images/icon/left.png" />
</div>

<div>
  <p className="text-3xl font-semibold">加盟优势</p>
</div>

<div className="mx-auto">
  <img src="./images/icon/right.png" />
</div>
        </div>
        <div className="bg-white p-8 rounded-xl mx-2 lg:mx-0 text-center">
<div className={`grid ${styles.youshigrid} border-solid border-b-2 border-gray-200 py-4`}>
    <div className={`${styles.pinkbg} rounded-tl-xl rounded-br-xl h-10 w-10 my-auto`}>
      <p className="text-xl pt-1 text-white font-semibold">1</p>
    </div>
    <div className="grid lg:grid-rows-2 gap-2 justify-self-start text-left">
      <p className="font-semibold text-lg justify-self-start">线上申请加入猫咪联盟计划一分钟最快速完成</p>
      <p className="text-base text-gray-500">线上申请登入管理系统即可马上可取得行销网址，立即开始推广赚钱。</p>
    </div>
</div>
<div className={`grid ${styles.youshigrid} border-solid border-b-2 border-gray-200 py-4`}>
<div className={`${styles.pinkbg} rounded-tl-xl rounded-br-xl h-10 w-10 my-auto`}>
<p className="text-xl pt-1 text-white font-semibold">2</p>
    </div>
    <div className="grid lg:grid-rows-2 gap-2 justify-self-start text-left">
      <p className="font-semibold text-lg justify-self-start">抽成奖金高</p>
      <p className="text-base text-gray-500">我们提供用户消费的30%作为你的推广奖金。</p>
    </div>
</div>
<div className={`grid ${styles.youshigrid} border-solid border-b-2 border-gray-200 py-4`}>
<div className={`${styles.pinkbg} rounded-tl-xl rounded-br-xl h-10 w-10 my-auto`}>
<p className="text-xl pt-1 text-white font-semibold">3</p>
    </div>
    <div className="grid lg:grid-rows-2 gap-2 justify-self-start text-left">
      <p className="font-semibold text-lg justify-self-start">专属您的推广网址/app</p>
      <p className="text-base text-gray-500">◆ 只要用户是从您的专属网址进入，不管他使用的是哪一部设备，系统均会判定该用户是您带来的，而且您的用户只要持续消费，您的奖金可以领一辈子，绝不漏算任何一笔奖金。</p>
      <p className="text-base text-gray-500">◆ 提供每个加盟商多组网址：可以依您的需求，绑定使用不同网址，达到最佳行销效益并提高您的收入。</p>
    </div>
</div>
<div className={`grid ${styles.youshigrid} border-solid border-b-2 border-gray-200 py-4`}>
<div className={`${styles.pinkbg} rounded-tl-xl rounded-br-xl h-10 w-10 my-auto`}>
<p className="text-xl pt-1 text-white font-semibold">4</p>
    </div>
    <div className="grid lg:grid-rows-2 gap-2 justify-self-start text-left">
      <p className="font-semibold text-lg justify-self-start">免成本，稳赚不赔</p>
      <p className="text-base text-gray-500">不必任何费用与投资，不需会写任何的程式码，不需要相关经验。您唯一要做的事，就是加入猫咪AV联盟计划，并将网站提供给您的用户加到您网站中即可获得收入！</p>
    </div>
</div>
<div className={`grid ${styles.youshigrid} border-solid border-b-2 border-gray-200 py-4`}>
<div className={`${styles.pinkbg} rounded-tl-xl rounded-br-xl h-10 w-10 my-auto`}>
<p className="text-xl pt-1 text-white font-semibold">5</p>
    </div>
    <div className="grid lg:grid-rows-2 gap-2 text-left">
      <p className="font-semibold text-lg">24小时客服值班为您服务</p>
      <p className="text-base text-gray-500">全年无休客服专线，有问题不会找不到人解决。稳定的聊天系统与完善的客服咨询，提供所有猫咪AV联盟伙伴最好的服务，保障消费安全。</p>
    </div>
</div>
<div className={`grid ${styles.youshigrid} border-solid border-b-2 border-gray-200 py-4`}>
<div className={`${styles.pinkbg} rounded-tl-xl rounded-br-xl h-10 w-10 my-auto`}>
<p className="text-xl pt-1 text-white font-semibold">6</p>
    </div>
    <div className="grid lg:grid-rows-2 gap-2 text-left">
      <p className="font-semibold text-lg">个人账户即时且透明化</p>
      <p className="text-base text-gray-500">猫咪AV联盟伙伴可随时登入查询即时的帐务明细，完全透明公开。</p>
    </div>
</div>
<div className={`grid ${styles.youshigrid} border-solid border-b-2 border-gray-200 py-4`}>
<div className={`${styles.pinkbg} rounded-tl-xl rounded-br-xl h-10 w-10 my-auto`}>
<p className="text-xl pt-1 text-white font-semibold">7</p>
    </div>
    <div className="grid lg:grid-rows-2 gap-2 text-left">
      <p className="font-semibold text-lg">7、亚洲第一成人社区AV，流量高</p>
      <p className="text-base text-gray-500">千万狼友的性福聚集地，24小时不间断更新内容</p>
    </div>
</div>

</div>
<div className={`grid ${styles.balancegrid} items-center justify-center mt-8 mb-6 text-center`}>
<div className="mx-auto">
  <img src="./images/icon/left.png" />
</div>

<div>
  <p className="text-3xl font-semibold">奖金制度说明</p>
</div>

<div className="mx-auto">
  <img src="./images/icon/right.png" />
</div>
        </div>
        <div className="grid lg:grid-cols-2 gap-6 pb-12 mx-2 lg:mx-0">
  <div className="bg-white rounded-lg p-4">
    <ul className={`${styles.pinktext} list-disc pl-5 pb-3`}>
      <li className="text-left font-semibold">奖金说明</li>
    </ul>
        <ul className="list-none text-black pl-5 mb-0">
      <li className="text-left">推广奖金 = 月营业额 * 30% 。</li>
    </ul>
    <ul className="list-none text-black pl-5 mb-0">
      <li className="text-left">使用USDT(TRC20计算)，计算必须扣5%交易手续费。</li>
    </ul>
  </div>
  <div className="bg-white rounded-lg p-4">
  <ul className={`${styles.pinktext} list-disc pl-5 pb-3`}>
  <li className="text-left font-semibold">奖金发放日期</li>
    </ul>
        <ul className="list-none text-black pl-5 mb-0">
      <li className="text-left">一个月结算二次，1-15号 在20号结算 16-30号在5号结算。 </li>
    </ul>
    <ul className="list-none text-black pl-5 mb-0">
      <li className="text-left">一个月未滿人民幣200元，自動累計到下個结算日期一起結算！遇假日延后出款。</li>
    </ul>
  </div>
</div>
        {/* Other sections remain unchanged */}
        
      </div>

      {/* Footer */}
      <footer className="bg-white w-full py-4 text-center flex items-center justify-center">
        <img src="./images/icon/cs.png" alt="Avatar" className="pr-2" />
        <p className="text-red-500 text-sm underline">24小时在线客服</p>
      </footer>
    </div>
  );
}

export default LandingPage;
