
import Back from "@/components/back"
import Footer from "@/components/footer"
import CategoryHead from "@/components/categoryHead"
import React, { FC, useEffect, useState } from "react"
import styles from "./styles/setting.module.css"
import useUser from "@/hooks/useUser"
import useRoleAuth from "@/hooks/useRoleAuth"

const Setting: FC = () => {
    useRoleAuth()
    const [user] = useUser()
    const [newList, setNewList] = useState<any>([]);

    useEffect(() => {
        const _v = [...list] as any;
        _v[1]["value"] =  user?.phone_code && user?.phone ? `${user?.phone_code}${user?.phone}` : ''
        setNewList(_v)
    }, [user])

    return (
        <CategoryHead>
            <div className="plr mh700 mt80">
                <Back>个人设置</Back>
                <div className="mw1100">
                    {newList.map((x: any, i: number) => {
                        return <div className={`${styles.tx} fl ${i !== newList.length - 1 ? styles.line : ''}`} key={i} onClick={() => window.open(x.url)} >
                            <div className="point">{x.name}</div>
                            {x.value && <div className="mlauto">{x.value}</div>}
                        </div>
                    })}
                </div>
            </div>
            <Footer />
        </CategoryHead>

    )
}
export default Setting;

const list = [
    {
        name: "密码设置",
        url: "/user/setpwd",
    },
    {
        name: "绑定手机号码",
        url: "/user/bindphone"
    }
]