// src/components/Modal.tsx
import React from "react";
import styles from "./styles/Modal.module.css"; // Create this CSS module file for styling

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ open, onClose, children }) => {
  if (!open) return null; // If not open, render nothing

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div
        className="bg-white rounded-2xl w-80 xl:w-1/4"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        <div className={` ${styles.pinkbg} rounded-t-2xl p-2 flex items-center`}>
        <div className="flex-1 text-center">

        <p className="text-white font-semibold my-auto">申请加入联盟</p>
        </div>

        <button className="text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={onClose}>
          &times;
        </button>
        </div>

        <div className="border-solid border-b-2 mx-4 py-5">
            <p className="text-sm font-base text-gray-600">本公司對您的資料將完全保密，請安心填寫以下資料。本公將在 24 小時內審核您的加盟商資格，審核通過後將馬上寄出通知信件（內含登入加盟商管理後台的帳號及密碼）</p>
        </div>

        <div className={`grid ${styles.applygrid} mx-4 pt-5`}>
            <div className={`${styles.pinkbg} h-full w-2 rounded-xl `}></div>
        <p className="text-black font-bold">申请人信息</p>
        </div>

        {children}
      </div>
    </div>
  );
};

export default Modal;