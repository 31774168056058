import CategoryHead from '@/components/categoryHead';
import MainContent from './mainContent';
import TopicContent from './topicContent';
import { useParams } from 'react-router-dom';
import Footer from '@/components/footer';

function MainIndex() {
  const { channel } = useParams()
  const category = [] as any;
  
  return (
    <div>
      <CategoryHead showCategory flag={1}>
        {!channel && window.location.href.indexOf("topic") === -1 && <MainContent list={category} />}
        {window.location.href.indexOf("topic") >= 0 && <TopicContent list={category} />}
        <Footer />
      </CategoryHead>
    </div>
  );
}

export default MainIndex;
