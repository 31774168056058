import { useState } from "react"

const useFlipFlop = (flipStr: string, flopStr: string, ) => {
    const [value, setValue] = useState<any>(flipStr);

    const changeSelected = (idx: 0 | 1) => {
        setValue(idx ? flopStr : flipStr)
    }
    return [value, changeSelected] as [string, (e: number) => void]
}
export default useFlipFlop