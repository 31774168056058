import { useCallback, useEffect, useState } from "react"
import { Subject } from "rxjs";

export const modalSubject$ = new Subject<SModal>();

export interface IModal {
    cls?: string;
}

export enum EModalTypes {
    NORMAL = "NORMAL",
    VIP = "VIP",
    CHARGE = 'CHARGE',
    MASK = 'MASK',
    FEEDBACK = 'FEEDBACK',
    PAYMENT = 'PAYMENT',
    MOONCAKE = 'MOONCAKE',
    DLZIP = 'DLZIP',
    NOTICE = 'NOTICE',
    NOTICE_MSG = 'NOTICE_MSG',
    BUYTOPIC = 'BUYTOPIC'
}

interface SModal {
    id: EModalTypes
    innerTitle?: string;
    event?: (data: any) => void;
    btn?: boolean,
    btnName?: string;
    data?: any
    name?:string,
    child?: string,
    kefu?: boolean
}

const useModal = () => {
    const [modalData, setModalData] = useState<any>({
        id: "",
        show: false,
        btn: false,
        event: null,
        innerTitle: "",
        data: {},
        name: "",
        kefu: false
    })

    useEffect(() => {
        const sub = modalSubject$.subscribe((res) => {
            setModalData({
                ...modalData,
                innerTitle: res.innerTitle,
                show: true,
                btn: res.btn,
                event: res.event,
                id: res.id,
                data: res,
                kefu: res.kefu
            })
        })
        return () => sub.unsubscribe()
    }, [modalData])

    const closeModal = useCallback(() => {
        setModalData({
            ...modalData,
            show: false
        })
    }, [modalData])
    return [modalData, closeModal] as [any, () => void]
}
export default useModal